// Vars
var controller = new ScrollMagic.Controller();

// Hero
function parallaxHero() {
  var scrollVar = $(window).scrollTop();
  var ticking = false;

  // Hero
  var $depth2 = $('.hero .wrap');
  var $bottom = $('.bottom');

  // Counter
  var $counter = $('section.counter');

  // Sliders
  var $sliders = $('section.slider, .related-product');

  // Everything plz
  var $all = $('.hero .bg-img, .hero .wrap, .bottom, .list.post, .surfaces-systems .surface, .surfaces-systems .system');

  function parallax(){
    ticking = false;
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();
    var $postList = $('.posts.post-list');
    var $depth1 = $('.hero .bg-img, .hero .video');

    // if(windowWidth >= 768){

      // Hero
      var limitedScrollVar = scrollVar * 0.5;
      var opacity = (1 - scrollVar/($(window).height() * 1.4));
      var opacity2 = (1.05 - scrollVar/($(window).height() * 0.9));

      $depth1.css({ 'transform': 'translate3d(0px, ' + (0.7 * limitedScrollVar) + 'px, 0px)' });
      $depth2.css({ 'transform': 'translate3d(0px, ' + (0.3 * limitedScrollVar) + 'px, 0px)', 'opacity': opacity2 });

      if($bottom.length > 0) {
        $bottom.css({ 'opacity': opacity });
      }

      // Surfaces and Systems
      if($sliders.length > 0) {
        $sliders.css({
          'overflow': 'hidden'
        });
        $sliders.find('.bg-img').css({
          'height': '120%',
          'top': 'auto',
          'bottom': '0'
        });

        $sliders.each(function() {
          that = $(this);
          var thisRatio = 0.2;
          var thisScoll = (scrollVar - (that.offset().top - windowHeight)) * 0.5;
          var thisScrolled = thisScoll * thisRatio;
          var thisOffsetMax = that.height();
          var thisBg = that.find('.bg-img');

          if(scrollVar >= (that.offset().top - windowHeight) && thisScrolled <= thisOffsetMax) {
            thisBg.css({ 'transform': 'translate3d(0px, ' + (thisRatio * thisScoll * 1) + 'px, 0px)' });
          }
          else if(thisScrolled > thisOffsetMax) {
            thisBg.css({ 'transform': 'translate3d(0px, ' + thisOffsetMax + 'px, 0px)' });
          }
          else {
            thisBg.css({ 'transform': 'translate3d(0px, 0px, 0px)' });
          }
        });
      }
    // }
    // else {
    //   $all.css({ 'transform': 'initial', 'opacity': 1, 'top': 'auto' });
    // }

    // Counter
    if($counter.length > 0 && scrollVar >= ($counter.offset().top - (windowHeight * 0.8))) {

      $('.counter.svg-counter:not(.fired)').addClass('fired');
      $('section.counter p.num-counter:not(.fired)').each(function() {
        $(this).addClass('fired');

        var options = {
          useEasing : true,
        //   easingFn: easingFn,
          useGrouping : true,
          separator : ',',
          decimal : '.',
          prefix : '',
          suffix : ''
        };

        var demo = new CountUp(this, 0, $(this).data('count'), 0, 1, options);

        setTimeout(function() {
          demo.start();
        }, 500);
      });
    }
   }

  function requestTick() {
    if(!ticking) {
      window.requestAnimationFrame(parallax);
    }
    ticking = true;
  }

  $(window).scroll(function(){
    scrollVar = $(window).scrollTop();
    requestTick();
  });
  requestTick();
}

// Shapes
function moveDemShapes(el, startPos) {
  var that = $(el);
  var duration = window.innerHeight;
  var hook = 0.6;

  if(that.closest('.page-header').length > 0) {
    hook = 0.25;
  }

  // Move in
  var scene = new ScrollMagic.Scene({
    triggerElement: el,
    triggerHook: hook,
    offset: 0,
    duration: duration
  })
  .setTween(TweenMax.fromTo(el, 2, { x: startPos }, { x: "0%", ease: Quad.easeInOut }))
  // .addIndicators()
  .addTo(controller);

  $(window).on("resize", function() {
    scene.duration(window.innerHeight);
  });

  // // Move down
  // var scene2 = new ScrollMagic.Scene({
  //       triggerElement: el,
  //       triggerHook: 0,
  //       offset: 1000,
  //       duration: 1500
  //     })
  //     .setTween(TweenMax.fromTo(el, 2, { y: "0%" }, { y: "20%", ease: Quad.easeOut }))
  //     // .addIndicators()
  //     .addTo(controller);
}

function parallaxShapes() {

  if(window.innerWidth < 768) {
    return;
  }

  var $right = $('.shape-right');
  var $left = $('.shape-left');

  // Left
  if($left.length > 0) {
    $left.each(function(i, el) {
      moveDemShapes(el, "-100%");
    });
  }

  // Right
  if($right.length > 0) {
    $right.each(function(i, el) {
      moveDemShapes(el, "100%");
    });
  }
}

// Init
function parallaxInit() {
  parallaxHero();
  parallaxShapes();
}