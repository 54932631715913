// jshint ignore: start

////////////////////////
function roundDist(dist)
  {
  switch(true)
          {
          case (dist<10):
            return 10;
            break;
          case (dist<25):
            return 25;
            break;
          case (dist<50):
            return 50;
            break;
          case (dist<100):
            return 100;
            break;
           case (dist<250):
            return 250;
            break;

          case (dist>250):
           return 0;
            break;
            }
  }
///////////////////
function geolocate(address)
{
    globalGeocoder.geocode( { 'address': address}, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {  globalObj.state ='';
        globalObj.state ='';
        globalObj.cnt ='';
        for(var key in results[0].address_components)
        {
          if(results[0].address_components[key].types[0]=="administrative_area_level_1")
            globalObj.state =results[0].address_components[key].short_name;
          else if(results[0].address_components[key].types[0]=="country")
            globalObj.cnt =results[0].address_components[key].short_name;
          else if(results[0].address_components[key].types[0]=="locality")
            globalObj.locality =results[0].address_components[key].short_name;
          else
            {}
        }
        globalObj.center = results[0].geometry.location;
        setStateDist(globalObj.state,globalObj.center);
        Arbiter.publish( 'geolocation', null);

        } else {
          alert(address + ' not found');
        }
    });
}
//////////////

function dealerScroll()
{
var offset = $('.results-wrap').offset();

$('.map-grad').animate({
  scrollLeft:Math.abs($('.results-wrap').offset().left-$('.map-grad').offset().left-321)+578
  }, 1000);
}
/////////////////
function getResults(distance)
{
  var dist = distance?distance:null
  var data={};
  if(distance===undefined)
  {
    data={
            zip: $('#address').val(),
      origin: 'tc'
        }
  }else
  {
  data= {
            zip: $('#address').val(),
      origin: 'tc',
      radius: distance
        }
  }

  if($('#address').val()!=='')
  {
  j.ajax({
        type: 'GET',
        url: 'https://pefp.net/dealers_for_tc',
        data: data,
        dataType: 'jsonp',
        crossDomain: true
    }).done(function(response){
    //console.log(response);
    if(response[0] && response[0].length)
    {
      //console.log(response);
      noResultsWarning.closeWindow();
      Arbiter.publish( 'results', {"data":response});
    }else
    {
      noResultsWarning.setWindow(globalMap);
      $('.alert').html('We`re sorry but those search results did not return any matches. Please try increasing the search radius. If you still cannot find a dealer, please contact us.') ;
    }
    }).fail(function(error){
    //console.log(error);
    });
  }
  else
  {
    $('.results-wrap').empty();
    $('.alert').html('Please enter your postal/zip code') ;
  }
}
//////////////////////////////////////////
function clearMarkers() {
  for (var i = 0; i < allMarkers.length; i++ ) {
    allMarkers[i].setMap(null);
  }
  allMarkers.length = 0;
}
//////////////////////////////////////
function makeMarkerHTML(dealer)
{
   var html='';
   html=dealer.name?'<strong>'+dealer.name+'</strong>'+'<br>':'';
   html+=dealer.address?dealer.address+'<br>':'';
   html+=dealer.city+', '+dealer.state+'<br>';
   html+=dealer.postal?dealer.postal+'<br>':'';
   html+=dealer.phone?'Tel: '+dealer.phone+'<br>':'';
   if(dealer.url)
   {
   var href = (!/^(f|ht)tps?:\/\//i.test(dealer.url))?("http://" + dealer.url):dealer.url;
   html+='<a href="'+href+'">Website</a><br>';
   }
   html+=dealer.distance?'Distance: '+Number(dealer.distance).toFixed(1) +' miles ('+Number(dealer.distance*1.6).toFixed(1)+' km)<br>':'';
   return html;
}
////////////////////////////////////////////////////////
function changeDistance(distance,e)
{
  if(distance>=gMinDistance)
  {
    $('.results-wrap').empty();
    clearMarkers();
    updateDistanceRadio(distance);
    globalObj.distance = distance;
    getResults(distance);
  }
  return false;
}
/////////////////////////////////////////////////////////////
function searchLocations()
{
  var bounds = new google.maps.LatLngBounds();
  var afterGeolocated = function(){};
  $('.results-wrap').empty();
  clearMarkers();
  globalObj.distance=-1;
  geolocate($('#address').val());
}
//////////////////////////////////////////////////////////
function updateDistanceRadio(distance)
{
    //console.log(distance)
    $('.cur-dist').each(function(){$(this).removeClass('cur-dist')});
    $('.r-option').children("label:contains('"+distance+"')").filter(function() {return $(this).text() == distance+'ml';}).parent().addClass('cur-dist');
}
//////////////////////////////////////////////////////////////////
function afterResaults(published_data){

    globalObj.results = published_data.data[0];
    var bounds = new google.maps.LatLngBounds();
  /*  $('.alert').empty();
      for(var i=0 ; i<=globalObj.results.length-1;i++ )
      {
      dealer = globalObj.results[i];
        //$('.results-wrap').append( $('<div>').addClass('dealer').html(makeMarkerHTML(dealer)) );
      }
    */
      if(globalObj.results == 0) {
        noResultsWarning.setWindow(globalMap);
      }
      j.each(globalObj.results, function(i,r)
      {

        if(i==0)
        {
          gMinDistance = roundDist(r.distance);
          if(globalObj.distance==-1)
          {
            globalObj.distance = roundDist(r.distance);
            updateDistanceRadio(globalObj.distance);
          }
        }
        var div = $('<div>').addClass('dealer').html(makeMarkerHTML(globalObj.results[i]));
        $('.results-wrap').append( div );
        var point = new google.maps.LatLng(parseFloat(globalObj.results[i].lat), parseFloat(globalObj.results[i].lng));
        var infoWindow = new google.maps.InfoWindow({content: makeMarkerHTML(globalObj.results[i])});
        var marker = new google.maps.Marker({
          position: point,
          map: globalMap,
          title:globalObj.results[i].name
        });

        allMarkers.push( marker );
        bounds.extend(point);

            google.maps.event.addListener(marker, 'click', function() {
          if(openInfoWindow) {
            openInfoWindow.close();
          }
          infoWindow.open(globalMap, marker);
          openInfoWindow = infoWindow;
            });

         google.maps.event.addDomListener(div.get(0), 'click', function() {
              if(openInfoWindow) { openInfoWindow.close(); }
              infoWindow.open(globalMap, marker);
              openInfoWindow = infoWindow;
            });

      });
      bounds.extend(globalObj.center);
      globalMap.fitBounds(bounds);
  };
////////////////////////////////////////////////////////////////////////////////
function getDists()
{
  var base_url = 'http://pefp.net/api';
  var method = 'UserList';
  var url = base_url+'/'+method+'/';
  var resObj ;
  var filters = new Array();
  var attributes = new Array('dealer_name','phone','address','fax','url');
  ////////////// CORS Call /////////
  var xhr = $.ajax({
   contentType: 'text/plain',
    xhrFields: {
        withCredentials: false
      },
    url: url,
    type: "GET",
    data: {
      attributes: attributes
    }
  }).done( function(plresponse){
    gDists=plresponse;
    Arbiter.publish('distributor',null);
  });
}
//getDists();
///////////////////////////////////////////////////////////////////////////////////////
function setStateDist(state,point)
  {

  $('.distributor p').empty();
  var relObject =
  {
    rel:{
      //'Northwest Stoves':['AB','BC','YT'],
      'Northwest Stoves':['BC','YT'],
      'The Foundry' :['ON'],
      'Maison DF':['QC'],
      'Tri State Distributors' : ['AK','MT','OR','WA','WY','NV','ID','UT'],
      'Associated Energy Systems' :['CA','CO','NV','HI','NV'],
      'Even Temp' :['AR','IL','IA','KS','LA','MN','MO','NE','ND','OK','SD','TX','WI'],
      'Total Hearth & Grill' : ['AL','DE','FL','GA','IN','KY','MD','MI','MS','NC','OH','PA','SC','TN','VA','WV'],
      'BAC Sales' : ['CT','NJ','NY'],
	  'Northeast Distribution':['ME','MA','NH','RI','VT'],
	  'Compact Appliances ltd.' :['NL','NS','NB','PE'],
      'Northern Fireplace Saskatchewan':['SK'],
      'Diamond Fireplace' : ['AB'],
      'Arizona Fireplaces South':['AZ','NM'],
      'Flame & Comfort':['MB']
    },
    exeptions:
    {
      locality:[['Cheyenne','Associated Energy Systems'],['Laramie','Associated Energy Systems']],
      latBigger:[{lat:52.2681120,state:'AB',dist:'Northern Fireplace Saskatchewan'}]
      //lat:52.268056
    },
    checkExeptions:function()
    {
      if(globalObj.locality!==null && globalObj.locality!=="undefined" )
      {
        for(var key2 in this.exeptions.locality)
          {
            if( globalObj.locality == this.exeptions.locality[key2][0] )
              return this.exeptions.locality[key2][1];
          }
      }
      if(globalObj.lat!==null && globalObj.lat!=="undefined" ){
        for(var key in this.exeptions.latBigger)
          {

            if(globalObj.center.lat() >= this.exeptions.latBigger[key].lat && globalObj.state == this.exeptions.latBigger[key].state)
              return this.exeptions.latBigger[key].dist;
          }
      }
      return 0;
    },
    getDist: function(passedState)
    {
      var lookup = this.rel;
      var res=[];
      for (var key in lookup)
      {
        for (var key2 in lookup[key])
        {
          if(passedState == lookup[key][key2] )
          res.push(key);
        }
      }
      return res;
    }
  }
	var makeDistHTML = function(dist) {
		var h='';
		if(dist)
		{
			var address = dist.address.address1;
			address += dist.address.address2?(dist.address.address2):'';
			address +=  '<br>';
			address += dist.address.city+', '+dist.address.state_province+', '+dist.address.postal_code;
			//$distHtml = '<strong>'+dist.dealer_name+'</strong><br>'+address+'<br>'+dist.phone+'<br><a href="'+href+'">website</a>';
			$('.distributor p').append( '<strong>'+dist.dealer_name+'</strong><br>'+address+'<br>');
			if(dist.phone)
			{
				$('.distributor p').append( dist.phone+'<br>');
			}
			if(dist.url)
			{
				var href = (!/^(f|ht)tps?:\/\//i.test(dist.url))?("http://" + dist.url):dist.url;
				$('.distributor p').append('<a href="'+href+'" target="_blank">Visit Website</a><br/>');
			}
		}
		return h;
	}
  //resObj = JSON.parse(gDists);
    // Van Isle check
     var triangleCoords = [
     new google.maps.LatLng(49.196064,-126.628418),
     new google.maps.LatLng(48.297812,-123.793945),
     new google.maps.LatLng(48.312428,-122.958984),
     new google.maps.LatLng(48.647428,-122.67334),
     new google.maps.LatLng(48.951366,-123.134766),
     new google.maps.LatLng(49.396675,-124.035645),
     new google.maps.LatLng(49.696062,-124.277344),
     new google.maps.LatLng(49.95122,-125.068359),
     new google.maps.LatLng(50.134664,-125.310059),
     new google.maps.LatLng(50.387508,-125.463867),
     new google.maps.LatLng(50.541363,-126.782227),
     new google.maps.LatLng(50.680797,-126.870117),
     new google.maps.LatLng(50.805935,-127.287598),
     new google.maps.LatLng(51.055207,-128.012695),
     new google.maps.LatLng(50.861444,-129.04541),
     new google.maps.LatLng(49.639177,-127.353516),
     new google.maps.LatLng(49.196064,-126.628418)
     ];
     vanIsle = new google.maps.Polygon({
      paths: triangleCoords
     });
     var tempD = relObject.checkExeptions();
    if( tempD )
	{
      $('.distributor p').append(makeDistHTML(globalObj.distributors[tempD]))
	}
    else if( (matchedStates = relObject.getDist(state)) && !(google.maps.geometry.poly.containsLocation(point,vanIsle)) )
    {
      for(key in matchedStates)
      {
        var dist = globalObj.distributors[matchedStates[key]];
        $('.distributor p').append(makeDistHTML(dist))
      }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadMap(lat,lng) {

    lat = typeof lat !== 'undefined' ? lat : 40;
      lng = typeof lng !== 'undefined' ? lng : -100;
      var latlng = new google.maps.LatLng(lat, lng); // usa centered
    var options = {
        zoom: 10,
          center: latlng,
          mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      globalMap = new google.maps.Map($('.ccm-locator-map').get(0), options);
      globalGeocoder = new google.maps.Geocoder();
}
///////////////////////